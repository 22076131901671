import db from './firebaseData';
import {
    collection,
    getDocs,
    orderBy,
    query,
} from 'firebase/firestore';

export default async function descargarDatosGuardados() {
    const q = query(collection(db, `transportes`), orderBy("timestamp"));
    const docs = await getDocs(q);
    let data = [];
    docs.forEach((doc) => { data.push(doc.data()) });
    return data;
}