import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

import DialogErrorHandler from '../components/DialogErrorHandler';

import DBdemostrarQueAbrioFormulario from '../firebase/DBdemostrarQueAbrioFormulario';
import dbGuardar from '../firebase/dbGuardar'
import validarRFC from '../functions/validarRFC';

import opcionesResidenciaFiscal from '../datos/opcionesResidenciaFiscal';
import opcionesRegimenFiscal from '../datos/opcionesRegimenFiscal'
import opcionesUsoCfdi from '../datos/opcionesUsoCfdi'
import codigosPostales from '../datos/codigosPostales'

export default function Index() {

    const [nombre, setNombre] = useState('')
    const [rfc, setRfc] = useState('')
    const [residenciaFiscal, setResidenciaFiscal] = useState('MEX')
    const [IDregistroTributario, setIDregistroTributario] = useState('')
    const [regimenFiscal, setRegimenFiscal] = useState('')
    const [codigoPostal, setCodigoPostal] = useState('')
    const [usoDeCFDI, setUsoDeCFDI] = useState('')
    const [pkCliente, setPkCliente] = useState('')


    const [cargando, setCargando] = useState(false)
    const [terminado, setTerminado] = useState(false)
    const [error, setError] = useState('')
    const [dialogError, setDialogError] = useState(false)

    const [params, setParams] = useSearchParams();
    let params_nombre = params.get('nombre')
    let params_rfc = params.get('RFC')
    let params_resideciaFiscal = params.get('residencia')
    let params_idRegistroTributario = params.get('registro') //text libre sin guiones ni espacios
    let params_regimenFiscal = params.get('regimen')
    let params_codigoPostal = params.get('cpostal') //texto libre con validación
    let params_usoDeCfdi = params.get('usoCFDI')
    let params_pkCliente = params.get('pkCliente')

    useEffect(() => {
        if (params_nombre) { setNombre(params_nombre) }
        if (params_rfc) { setRfc(params_rfc) }
        if (params_resideciaFiscal) { setResidenciaFiscal(params_resideciaFiscal) }
        if (params_idRegistroTributario) { setIDregistroTributario(params_idRegistroTributario) }
        if (params_regimenFiscal) { setRegimenFiscal(params_regimenFiscal) }
        if (params_codigoPostal) { setCodigoPostal(params_codigoPostal) }
        if (params_usoDeCfdi) { setUsoDeCFDI(params_usoDeCfdi) }
        if (params_pkCliente) {
            setPkCliente(params_pkCliente)
            DBdemostrarQueAbrioFormulario(params_pkCliente, params_nombre)
        }
    }, [])


    const validarDatosYEnviar = async () => {

        if(pkCliente === ''){
            setError('yaExiste')
            setDialogError(true)
            return
        }

        if (nombre === '') {
            setError('nombre')
            setDialogError(true)
            return
        }

        const rfcOK = validarRFC(rfc, setError)
        if (!rfcOK) {
            setDialogError(true)
            return
        }

        if (residenciaFiscal === '') {
            setError('resF')
            setDialogError(true)
            return
        }



        if (regimenFiscal === '') {
            setError('regF')
            setDialogError(true)
            return
        }

        const codigoEncontrado = codigosPostales.find(codigo => codigo === parseInt(codigoPostal))
        if (!codigoEncontrado) {
            setError('cp')
            setDialogError(true)
            return
        }

        if (usoDeCFDI === '') {
            setError('usoCFDI')
            setDialogError(true)
            return
        }

        const obj = {
            nombre,
            rfc,
            residenciaFiscal,
            IDregistroTributario,
            regimenFiscal,
            codigoPostal,
            usoDeCFDI,
            pkCliente,
        }

        setError('')

        const guardado = await dbGuardar(obj)
        if (!guardado) {
            setError('yaExiste')
            setDialogError(true)
        } else {
            setTerminado(true)
        }
    }


    const submit = async (e) => {
        e.preventDefault()
        setCargando(true)
        if (!terminado) {
            await validarDatosYEnviar()
        }
        setCargando(false)
    }

    return (
        <div className='index-container' >
            <Paper sx={{
                width: '100%',
                maxWidth: '500px',
                margin: 'auto auto',
                p: 2,
                background: 'rgba(255, 255, 255, 0.85)'
            }} >

                <Box sx={{ display: 'flex', justifyContent: 'center', p: 3, pt: 0 }} >
                    <img src='https://tqpytokz.cdn.imgeng.in/media-adsa/static/3991/984.png' alt='logo' />
                </Box>

                <form onSubmit={submit} >
                    {!terminado &&
                        <Stack direction='column' spacing={1} >

                            <TextField
                                type='text'
                                fullWidth
                                disabled
                                variant='standard'
                                label='Número de cliente'
                                value={pkCliente}
                            />

                            <TextField
                                type='text'
                                fullWidth
                                label='Nombre'
                                value={nombre}
                                disabled={terminado}
                                onChange={e => setNombre(e.target.value)}
                                color={error === 'nombre' ? 'error' : 'primary'}
                                focused={error === 'nombre' ? true : undefined}
                            />
                            <TextField
                                type='text'
                                fullWidth
                                label='RFC'
                                disabled={terminado}
                                value={rfc}
                                onChange={e => setRfc(e.target.value)}
                                color={(error === 'rfc' || 'rfc-mes' || 'rfc-dia') ? 'error' : 'primary'}
                                focused={error === 'rfc' ? true : undefined}
                            />

                            <FormControl
                                fullWidth
                                color={error === 'resF' ? 'error' : 'primary'}
                                focused={error === 'resF' ? true : undefined}
                                disabled={terminado}
                            >
                                <InputLabel>Residencia fiscal</InputLabel>
                                <Select
                                    value={residenciaFiscal}
                                    label="Residencia fiscal"
                                    onChange={e => setResidenciaFiscal(e.target.value)}
                                >
                                    {
                                        opcionesResidenciaFiscal.map(
                                            op => <MenuItem
                                                key={op.clave}
                                                value={op.clave}
                                            >
                                                {`${op.clave} - ${op.valor}`}
                                            </MenuItem>)
                                    }
                                </Select>
                            </FormControl>

                            {residenciaFiscal !== 'MEX' &&
                                <TextField
                                    type='text'
                                    fullWidth
                                    label='ID registro tributario (Sólo requerido para extranjeros)'
                                    value={IDregistroTributario}
                                    onChange={e => setIDregistroTributario(e.target.value)}
                                    color={error === 'IDrt' ? 'error' : 'primary'}
                                    focused={error === 'IDrt' ? true : undefined}
                                    disabled={terminado}
                                />
                            }
                            <FormControl
                                fullWidth
                                color={error === 'regF' ? 'error' : 'primary'}
                                focused={error === 'regF' ? true : undefined}
                                disabled={terminado}
                            >
                                <InputLabel>Régimen fiscal</InputLabel>
                                <Select
                                    value={regimenFiscal}
                                    label="Régimen fiscal"
                                    onChange={e => setRegimenFiscal(e.target.value)}
                                >
                                    {
                                        opcionesRegimenFiscal.map(
                                            op => <MenuItem
                                                key={op.clave}
                                                value={op.clave}
                                            >
                                                {`${op.clave} - ${op.valor}`}
                                            </MenuItem>)
                                    }
                                </Select>
                            </FormControl>



                            <TextField
                                type='text'
                                fullWidth
                                label='Código postal'
                                value={codigoPostal}
                                onChange={e => setCodigoPostal(e.target.value)}
                                color={error === 'cp' ? 'error' : 'primary'}
                                focused={error === 'cp' ? true : undefined}
                                disabled={terminado}
                            />


                            <FormControl
                                fullWidth
                                color={error === 'usoCFDI' ? 'error' : 'primary'}
                                focused={error === 'usoCFDI' ? true : undefined}
                                disabled={terminado}
                            >
                                <InputLabel>Uso de CFDI</InputLabel>
                                <Select
                                    value={usoDeCFDI}
                                    label="Uso de CFDI"
                                    onChange={e => setUsoDeCFDI(e.target.value)}
                                >
                                    {
                                        opcionesUsoCfdi.map(
                                            op => <MenuItem key={op.clave} value={op.clave} >
                                                {`${op.clave} - ${op.valor}`}
                                            </MenuItem>)
                                    }
                                </Select>
                            </FormControl>

                        </Stack>
                    }

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }} >
                        <LoadingButton
                            size="small"
                            color={terminado ? 'success' : "primary"}
                            type='submit'
                            loading={cargando}
                            loadingPosition="start"
                            startIcon={terminado ? <CheckIcon /> : <SaveIcon />}
                            variant="contained"
                            sx={{ width: 'fit-content', m: '0 auto' }}
                        >
                            {terminado ? "Datos guardados correctamente" : "Guardar"}
                        </LoadingButton>
                    </Box>
                </form>

                <DialogErrorHandler useOpen={[dialogError, setDialogError]} error={error} />

            </Paper>
        </div>
    )
}