import {
    Box,
    Paper,
    Button,
    LinearProgress,
    CircularProgress,
    TextField,
    Typography,
    Stack,
} from '@mui/material';
import axios from 'axios';
import { useState, useEffect } from 'react';
import descargarDatosGuardados from '../firebase/descargarDatosGuardados';
import validarPwd from '../firebase/validarPwd';

export default function Links() {

    const [respuestas, setRespuestas] = useState([])
    const [file, setFile] = useState('')
    const [data, setData] = useState([])
    const [progreso, setProgreso] = useState(0)
    const [indiceActual, setIndiceActual] = useState(0)
    const [cicloIniciado, setCicloIniciado] = useState(false)
    const [errores, setErrores] = useState([])
    const [cantidadEnviada, setCantidadEnviada] = useState(0)
    const [enviando, setEnviando] = useState(false)

    const [pwd, setPwd] = useState('')
    const [validado, setValidado] = useState(false)



    const cargaOK = (e) => {
        const archivo = e.target.files[0]
        if (!archivo) { return }
        const lector = new FileReader()
        lector.onload = function (e) {
            const contenido = e.target.result
            setData(JSON.parse(contenido))
        }
        lector.readAsText(archivo)
        setFile('')
    }

    useEffect(() => {
        setTimeout(() => {
            if (cicloIniciado === true && indiceActual < data.length) {
                setProgreso((100 / data.length) * (indiceActual))
                mapeo()
            } else {
                // console.log('Lista de errores')
                // console.log(JSON.stringify(errores))
                setCicloIniciado(false)
                setIndiceActual(0)
                setEnviando(false)
                setProgreso(0)
            }
        }, 2250)
    }, [indiceActual, cicloIniciado])

    async function mapeo() {
        let nuevasRespuestas = [...respuestas]

        let nuevosErrores = [...errores]

        await axios.post(
            'http://localhost:3000/api/enviar',
            data[indiceActual]
        )
            .then((res) => {
                const estado = {
                    clave: res.data.info.messageId,
                    correo: res.data.info.accepted[0],
                    status: res.data.info.response,
                    pkcliente: res.data.pkcliente
                }
                nuevasRespuestas.push(estado)
                if (estado.status === '500') {
                    nuevosErrores.push(data[indiceActual])
                    setErrores(nuevosErrores)
                }
                setRespuestas(nuevasRespuestas)
                setIndiceActual(indiceActual + 1)
                setCantidadEnviada(cantidadEnviada + 1)
            })
            .catch(err => {
                console.error(data[indiceActual].pkcliente, err)
                let nuevaData = [...data]
                nuevasRespuestas.push({
                    clave: data[indiceActual].pkcliente,
                    correo: data[indiceActual].correo,
                    status: '500 BAD',
                    pkcliente: data[indiceActual].pkcliente
                })
                nuevosErrores.push(data[indiceActual])
                setRespuestas(nuevasRespuestas)
                setCicloIniciado(false)
                setEnviando(false)
                setIndiceActual(0)
                setProgreso(0)
                nuevaData.splice(0, indiceActual)
                setData(nuevaData)
            })
    }


    const enviar = () => {
        setEnviando(true)
        setCicloIniciado(true)
        setRespuestas([])
    }



    const paraHacienda = async () => {

        const data = await descargarDatosGuardados()

        let string = ''
        data.map(function (dato, index) {
            string = string + `${index}|${dato.rfc}|${dato.nombre}|${dato.codigoPostal}\n`
        })

        const element = document.createElement("a");
        const file = new Blob([string], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "registros para hacienda.txt";
        document.body.appendChild(element);
        element.click();

    }

    const paraActualizarDatos = async () => {
        const data = await descargarDatosGuardados()

        let string = ''
        data.map(function (dato, index) {

            const fecha = new Date(dato.timestamp.seconds*1000)

            const fechaTexto = `${fecha.getDate()}/${fecha.getMonth()+1}/${fecha.getFullYear()} ${fecha.getHours()}:${fecha.getMinutes()}`

            string = string + `${dato.pkCliente}|${fechaTexto}|${dato.nombre}|${dato.rfc}|${dato.codigoPostal}|${dato.residenciaFiscal}|${dato.regimenFiscal}|${dato.usoDeCFDI}|${dato.IDregistroTributario}\n`
        })

        const element = document.createElement("a");
        const file = new Blob([string], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "actualizar datos.txt";
        document.body.appendChild(element);
        element.click();
    }


    const intentaIngreso = async () => {
        const pwdOK = await validarPwd(pwd)
        setPwd('')
        if(pwdOK) {
            setValidado(true)
        } else {
            setPwd('Error. Intentar otra vez')
        }
    }

    if (!validado) {
        return (
            <Paper sx={{ m: '0 auto', p: 2, width: '100%', maxWidth: '500px', mt: 4, background: '#fafafa', border: 'solid 1px #ddd', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <TextField
                    fullWidth
                    label='Contraseña'
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    onFocus={()=>setPwd('')}
                />
                <Button variant='contained' onClick={intentaIngreso} sx={{ mt: 2 }} >Aceptar</Button>
            </Paper>
        )
    }

    return (
        <Paper sx={{ m: '0 auto', p: 2, width: '100%', maxWidth: '900px', mt: 1, background: '#fafafa', border: 'solid 1px #ddd' }} >
            <Typography sx={{ textAlign: 'center', mb: 2 }} variant='h6' ><b>Enviar emails</b></Typography>

            <input type='file' style={{ display: 'none' }} id='btn-carga' value={file} onChange={cargaOK} />

            {
                enviando
                    ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                            <CircularProgress />
                            <Typography>Enviando...</Typography>
                        </Box>
                    ) : (
                        <Stack spacing={1} direction='row' sx={{ m: '0 auto', justifyContent: 'center' }} >

                            <label htmlFor='btn-carga' >
                                <Button component='span' variant={data.length > 0 ? 'outlined' : 'contained'} >
                                    {data.length > 0 ? `Datos cargados: ${data.length}` : "Cargar archivo txt"}
                                </Button>
                            </label>
                            {
                                data.length > 0 &&
                                <Button variant='contained' sx={{ width: 'fit-content' }} onClick={enviar} >
                                    Empezar a enviar emails
                                </Button>
                            }
                        </Stack>
                    )
            }

            {
                enviando &&
                <Box sx={{ width: '100%', mt: 3 }}>
                    <Typography>Progreso: </Typography>
                    <LinearProgress variant="determinate" value={progreso} />
                </Box>
            }
            <Typography sx={{ mt: 3 }} >Registro:</Typography>

            <Box sx={{ background: 'white', border: 'solid 1px #bbb', p: 2, mb: 2, borderRadius: '10px', minHeight: '450px', maxHeight: '600px', overflow: 'auto' }} >

                {
                    respuestas.map((res, index) => <Typography key={res.clave} sx={{ color: (res.status === '250 OK' ? 'green' : 'red') }} >

                        {
                            res.status === '250 OK'
                                ? `Correo a ${res.pkcliente} - ${res.correo} enviado con éxito`
                                : res.status === '500'
                                    ? `Correo a ${res.pkcliente} inexistente. Favor de verificarlo.`
                                    : `Correo a ${res.pkcliente} - ${res.correo} no enviado. Código de error: ${res.status}`
                        }

                    </Typography>)
                }

            </Box>

            <Stack spacing={2} >
                <Button onClick={paraHacienda} variant='contained' >
                    Descargar txt para registro de hacienda
                </Button>
                <Button onClick={paraActualizarDatos} variant='contained' >
                    Descargar txt para actualización de datos en el sistema
                </Button>
            </Stack>

        </Paper >
    )
}