import Index from './pages/Index';
import {Routes, Route} from 'react-router-dom';
import Links from './pages/Links'

export default function App() {
    return (
        <Routes>
            <Route index element={<Index />} />
            <Route path='links' element={<Links />} />
        </Routes>
    )
}