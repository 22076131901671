import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
const firebaseApp = initializeApp({
  apiKey: "AIzaSyClGyNKnkZ5Y4vYFR1Boiu903tt9kXvSqU",
  authDomain: "datos-gatsby-sami.firebaseapp.com",
  databaseURL: "https://datos-gatsby-sami.firebaseio.com",
  projectId: "datos-gatsby-sami",
  storageBucket: "datos-gatsby-sami.appspot.com",
  messagingSenderId: "315399193727",
  appId: "1:315399193727:web:86ef249ab48db09d65eb4a",
  measurementId: "G-1ZD995V8M8",
});
const db = getFirestore(firebaseApp)
export default db;