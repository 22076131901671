const opcionesResidenciaFiscal = [
    {
        "clave": "AFG",
        "valor": "Afganistán"
    },
    {
        "clave": "ALA",
        "valor": "Islas Åland"
    },
    {
        "clave": "ALB",
        "valor": "Albania"
    },
    {
        "clave": "DEU",
        "valor": "Alemania"
    },
    {
        "clave": "AND",
        "valor": "Andorra"
    },
    {
        "clave": "AGO",
        "valor": "Angola"
    },
    {
        "clave": "AIA",
        "valor": "Anguila"
    },
    {
        "clave": "ATA",
        "valor": "Antártida"
    },
    {
        "clave": "ATG",
        "valor": "Antigua y Barbuda"
    },
    {
        "clave": "SAU",
        "valor": "Arabia Saudita"
    },
    {
        "clave": "DZA",
        "valor": "Argelia"
    },
    {
        "clave": "ARG",
        "valor": "Argentina"
    },
    {
        "clave": "ARM",
        "valor": "Armenia"
    },
    {
        "clave": "ABW",
        "valor": "Aruba"
    },
    {
        "clave": "AUS",
        "valor": "Australia"
    },
    {
        "clave": "AUT",
        "valor": "Austria"
    },
    {
        "clave": "AZE",
        "valor": "Azerbaiyán"
    },
    {
        "clave": "BHS",
        "valor": "Bahamas (las)"
    },
    {
        "clave": "BGD",
        "valor": "Bangladés"
    },
    {
        "clave": "BRB",
        "valor": "Barbados"
    },
    {
        "clave": "BHR",
        "valor": "Baréin"
    },
    {
        "clave": "BEL",
        "valor": "Bélgica"
    },
    {
        "clave": "BLZ",
        "valor": "Belice"
    },
    {
        "clave": "BEN",
        "valor": "Benín"
    },
    {
        "clave": "BMU",
        "valor": "Bermudas"
    },
    {
        "clave": "BLR",
        "valor": "Bielorrusia"
    },
    {
        "clave": "BOL",
        "valor": "Bolivia, Estado Plurinacional de"
    },
    {
        "clave": "BIH",
        "valor": "Bosnia y Herzegovina"
    },
    {
        "clave": "BWA",
        "valor": "Botsuana"
    },
    {
        "clave": "BRA",
        "valor": "Brasil"
    },
    {
        "clave": "BRN",
        "valor": "Brunéi Darussalam"
    },
    {
        "clave": "BGR",
        "valor": "Bulgaria"
    },
    {
        "clave": "BFA",
        "valor": "Burkina Faso"
    },
    {
        "clave": "BDI",
        "valor": "Burundi"
    },
    {
        "clave": "BTN",
        "valor": "Bután"
    },
    {
        "clave": "CPV",
        "valor": "Cabo Verde"
    },
    {
        "clave": "KHM",
        "valor": "Camboya"
    },
    {
        "clave": "CMR",
        "valor": "Camerún"
    },
    {
        "clave": "CAN",
        "valor": "Canadá"
    },
    {
        "clave": "QAT",
        "valor": "Catar"
    },
    {
        "clave": "BES",
        "valor": "Bonaire, San Eustaquio y Saba"
    },
    {
        "clave": "TCD",
        "valor": "Chad"
    },
    {
        "clave": "CHL",
        "valor": "Chile"
    },
    {
        "clave": "CHN",
        "valor": "China"
    },
    {
        "clave": "CYP",
        "valor": "Chipre"
    },
    {
        "clave": "COL",
        "valor": "Colombia"
    },
    {
        "clave": "COM",
        "valor": "Comoras"
    },
    {
        "clave": "PRK",
        "valor": "Corea (la República Democrática Popular de)"
    },
    {
        "clave": "KOR",
        "valor": "Corea (la República de)"
    },
    {
        "clave": "CIV",
        "valor": "Côte d'Ivoire"
    },
    {
        "clave": "CRI",
        "valor": "Costa Rica"
    },
    {
        "clave": "HRV",
        "valor": "Croacia"
    },
    {
        "clave": "CUB",
        "valor": "Cuba"
    },
    {
        "clave": "CUW",
        "valor": "Curaçao"
    },
    {
        "clave": "DNK",
        "valor": "Dinamarca"
    },
    {
        "clave": "DMA",
        "valor": "Dominica"
    },
    {
        "clave": "ECU",
        "valor": "Ecuador"
    },
    {
        "clave": "EGY",
        "valor": "Egipto"
    },
    {
        "clave": "SLV",
        "valor": "El Salvador"
    },
    {
        "clave": "ARE",
        "valor": "Emiratos Árabes Unidos (Los)"
    },
    {
        "clave": "ERI",
        "valor": "Eritrea"
    },
    {
        "clave": "SVK",
        "valor": "Eslovaquia"
    },
    {
        "clave": "SVN",
        "valor": "Eslovenia"
    },
    {
        "clave": "ESP",
        "valor": "España"
    },
    {
        "clave": "USA",
        "valor": "Estados Unidos (los)"
    },
    {
        "clave": "EST",
        "valor": "Estonia"
    },
    {
        "clave": "ETH",
        "valor": "Etiopía"
    },
    {
        "clave": "PHL",
        "valor": "Filipinas (las)"
    },
    {
        "clave": "FIN",
        "valor": "Finlandia"
    },
    {
        "clave": "FJI",
        "valor": "Fiyi"
    },
    {
        "clave": "FRA",
        "valor": "Francia"
    },
    {
        "clave": "GAB",
        "valor": "Gabón"
    },
    {
        "clave": "GMB",
        "valor": "Gambia (La)"
    },
    {
        "clave": "GEO",
        "valor": "Georgia"
    },
    {
        "clave": "GHA",
        "valor": "Ghana"
    },
    {
        "clave": "GIB",
        "valor": "Gibraltar"
    },
    {
        "clave": "GRD",
        "valor": "Granada"
    },
    {
        "clave": "GRC",
        "valor": "Grecia"
    },
    {
        "clave": "GRL",
        "valor": "Groenlandia"
    },
    {
        "clave": "GLP",
        "valor": "Guadalupe"
    },
    {
        "clave": "GUM",
        "valor": "Guam"
    },
    {
        "clave": "GTM",
        "valor": "Guatemala"
    },
    {
        "clave": "GUF",
        "valor": "Guayana Francesa"
    },
    {
        "clave": "GGY",
        "valor": "Guernsey"
    },
    {
        "clave": "GIN",
        "valor": "Guinea"
    },
    {
        "clave": "GNB",
        "valor": "Guinea-Bisáu"
    },
    {
        "clave": "GNQ",
        "valor": "Guinea Ecuatorial"
    },
    {
        "clave": "GUY",
        "valor": "Guyana"
    },
    {
        "clave": "HTI",
        "valor": "Haití"
    },
    {
        "clave": "HND",
        "valor": "Honduras"
    },
    {
        "clave": "HKG",
        "valor": "Hong Kong"
    },
    {
        "clave": "HUN",
        "valor": "Hungría"
    },
    {
        "clave": "IND",
        "valor": "India"
    },
    {
        "clave": "IDN",
        "valor": "Indonesia"
    },
    {
        "clave": "IRQ",
        "valor": "Irak"
    },
    {
        "clave": "IRN",
        "valor": "Irán (la República Islámica de)"
    },
    {
        "clave": "IRL",
        "valor": "Irlanda"
    },
    {
        "clave": "BVT",
        "valor": "Isla Bouvet"
    },
    {
        "clave": "IMN",
        "valor": "Isla de Man"
    },
    {
        "clave": "CXR",
        "valor": "Isla de Navidad"
    },
    {
        "clave": "NFK",
        "valor": "Isla Norfolk"
    },
    {
        "clave": "ISL",
        "valor": "Islandia"
    },
    {
        "clave": "CYM",
        "valor": "Islas Caimán (las)"
    },
    {
        "clave": "CCK",
        "valor": "Islas Cocos (Keeling)"
    },
    {
        "clave": "COK",
        "valor": "Islas Cook (las)"
    },
    {
        "clave": "FRO",
        "valor": "Islas Feroe (las)"
    },
    {
        "clave": "SGS",
        "valor": "Georgia del sur y las islas sandwich del sur"
    },
    {
        "clave": "HMD",
        "valor": "Isla Heard e Islas McDonald"
    },
    {
        "clave": "FLK",
        "valor": "Islas Malvinas [Falkland] (las)"
    },
    {
        "clave": "MNP",
        "valor": "Islas Marianas del Norte (las)"
    },
    {
        "clave": "MHL",
        "valor": "Islas Marshall (las)"
    },
    {
        "clave": "PCN",
        "valor": "Pitcairn"
    },
    {
        "clave": "SLB",
        "valor": "Islas Salomón (las)"
    },
    {
        "clave": "TCA",
        "valor": "Islas Turcas y Caicos (las)"
    },
    {
        "clave": "UMI",
        "valor": "Islas de Ultramar Menores de Estados Unidos (las)"
    },
    {
        "clave": "VGB",
        "valor": "Islas Vírgenes (Británicas)"
    },
    {
        "clave": "VIR",
        "valor": "Islas Vírgenes (EE.UU.)"
    },
    {
        "clave": "ISR",
        "valor": "Israel"
    },
    {
        "clave": "ITA",
        "valor": "Italia"
    },
    {
        "clave": "JAM",
        "valor": "Jamaica"
    },
    {
        "clave": "JPN",
        "valor": "Japón"
    },
    {
        "clave": "JEY",
        "valor": "Jersey"
    },
    {
        "clave": "JOR",
        "valor": "Jordania"
    },
    {
        "clave": "KAZ",
        "valor": "Kazajistán"
    },
    {
        "clave": "KEN",
        "valor": "Kenia"
    },
    {
        "clave": "KGZ",
        "valor": "Kirguistán"
    },
    {
        "clave": "KIR",
        "valor": "Kiribati"
    },
    {
        "clave": "KWT",
        "valor": "Kuwait"
    },
    {
        "clave": "LAO",
        "valor": "Lao, (la) República Democrática Popular"
    },
    {
        "clave": "LSO",
        "valor": "Lesoto"
    },
    {
        "clave": "LVA",
        "valor": "Letonia"
    },
    {
        "clave": "LBN",
        "valor": "Líbano"
    },
    {
        "clave": "LBR",
        "valor": "Liberia"
    },
    {
        "clave": "LBY",
        "valor": "Libia"
    },
    {
        "clave": "LIE",
        "valor": "Liechtenstein"
    },
    {
        "clave": "LTU",
        "valor": "Lituania"
    },
    {
        "clave": "LUX",
        "valor": "Luxemburgo"
    },
    {
        "clave": "MAC",
        "valor": "Macao"
    },
    {
        "clave": "MDG",
        "valor": "Madagascar"
    },
    {
        "clave": "MYS",
        "valor": "Malasia"
    },
    {
        "clave": "MWI",
        "valor": "Malaui"
    },
    {
        "clave": "MDV",
        "valor": "Maldivas"
    },
    {
        "clave": "MLI",
        "valor": "Malí"
    },
    {
        "clave": "MLT",
        "valor": "Malta"
    },
    {
        "clave": "MAR",
        "valor": "Marruecos"
    },
    {
        "clave": "MTQ",
        "valor": "Martinica"
    },
    {
        "clave": "MUS",
        "valor": "Mauricio"
    },
    {
        "clave": "MRT",
        "valor": "Mauritania"
    },
    {
        "clave": "MYT",
        "valor": "Mayotte"
    },
    {
        "clave": "MEX",
        "valor": "México"
    },
    {
        "clave": "FSM",
        "valor": "Micronesia (los Estados Federados de)"
    },
    {
        "clave": "MDA",
        "valor": "Moldavia (la República de)"
    },
    {
        "clave": "MCO",
        "valor": "Mónaco"
    },
    {
        "clave": "MNG",
        "valor": "Mongolia"
    },
    {
        "clave": "MNE",
        "valor": "Montenegro"
    },
    {
        "clave": "MSR",
        "valor": "Montserrat"
    },
    {
        "clave": "MOZ",
        "valor": "Mozambique"
    },
    {
        "clave": "MMR",
        "valor": "Myanmar"
    },
    {
        "clave": "NAM",
        "valor": "Namibia"
    },
    {
        "clave": "NRU",
        "valor": "Nauru"
    },
    {
        "clave": "NPL",
        "valor": "Nepal"
    },
    {
        "clave": "NIC",
        "valor": "Nicaragua"
    },
    {
        "clave": "NER",
        "valor": "Níger (el)"
    },
    {
        "clave": "NGA",
        "valor": "Nigeria"
    },
    {
        "clave": "NIU",
        "valor": "Niue"
    },
    {
        "clave": "NOR",
        "valor": "Noruega"
    },
    {
        "clave": "NCL",
        "valor": "Nueva Caledonia"
    },
    {
        "clave": "NZL",
        "valor": "Nueva Zelanda"
    },
    {
        "clave": "OMN",
        "valor": "Omán"
    },
    {
        "clave": "NLD",
        "valor": "Países Bajos (los)"
    },
    {
        "clave": "PAK",
        "valor": "Pakistán"
    },
    {
        "clave": "PLW",
        "valor": "Palaos"
    },
    {
        "clave": "PSE",
        "valor": "Palestina, Estado de"
    },
    {
        "clave": "PAN",
        "valor": "Panamá"
    },
    {
        "clave": "PNG",
        "valor": "Papúa Nueva Guinea"
    },
    {
        "clave": "PRY",
        "valor": "Paraguay"
    },
    {
        "clave": "PER",
        "valor": "Perú"
    },
    {
        "clave": "PYF",
        "valor": "Polinesia Francesa"
    },
    {
        "clave": "POL",
        "valor": "Polonia"
    },
    {
        "clave": "PRT",
        "valor": "Portugal"
    },
    {
        "clave": "PRI",
        "valor": "Puerto Rico"
    },
    {
        "clave": "GBR",
        "valor": "Reino Unido (el)"
    },
    {
        "clave": "CAF",
        "valor": "República Centroafricana (la)"
    },
    {
        "clave": "CZE",
        "valor": "República Checa (la)"
    },
    {
        "clave": "MKD",
        "valor": "Macedonia (la antigua República Yugoslava de)"
    },
    {
        "clave": "COG",
        "valor": "Congo"
    },
    {
        "clave": "COD",
        "valor": "Congo (la República Democrática del)"
    },
    {
        "clave": "DOM",
        "valor": "República Dominicana (la)"
    },
    {
        "clave": "REU",
        "valor": "Reunión"
    },
    {
        "clave": "RWA",
        "valor": "Ruanda"
    },
    {
        "clave": "ROU",
        "valor": "Rumania"
    },
    {
        "clave": "RUS",
        "valor": "Rusia, (la) Federación de"
    },
    {
        "clave": "ESH",
        "valor": "Sahara Occidental"
    },
    {
        "clave": "WSM",
        "valor": "Samoa"
    },
    {
        "clave": "ASM",
        "valor": "Samoa Americana"
    },
    {
        "clave": "BLM",
        "valor": "San Bartolomé"
    },
    {
        "clave": "KNA",
        "valor": "San Cristóbal y Nieves"
    },
    {
        "clave": "SMR",
        "valor": "San Marino"
    },
    {
        "clave": "MAF",
        "valor": "San Martín (parte francesa)"
    },
    {
        "clave": "SPM",
        "valor": "San Pedro y Miquelón"
    },
    {
        "clave": "VCT",
        "valor": "San Vicente y las Granadinas"
    },
    {
        "clave": "SHN",
        "valor": "Santa Helena, Ascensión y Tristán de Acuña"
    },
    {
        "clave": "LCA",
        "valor": "Santa Lucía"
    },
    {
        "clave": "STP",
        "valor": "Santo Tomé y Príncipe"
    },
    {
        "clave": "SEN",
        "valor": "Senegal"
    },
    {
        "clave": "SRB",
        "valor": "Serbia"
    },
    {
        "clave": "SYC",
        "valor": "Seychelles"
    },
    {
        "clave": "SLE",
        "valor": "Sierra leona"
    },
    {
        "clave": "SGP",
        "valor": "Singapur"
    },
    {
        "clave": "SXM",
        "valor": "Sint Maarten (parte holandesa)"
    },
    {
        "clave": "SYR",
        "valor": "Siria, (la) República Árabe"
    },
    {
        "clave": "SOM",
        "valor": "Somalia"
    },
    {
        "clave": "LKA",
        "valor": "Sri Lanka"
    },
    {
        "clave": "SWZ",
        "valor": "Suazilandia"
    },
    {
        "clave": "ZAF",
        "valor": "Sudáfrica"
    },
    {
        "clave": "SDN",
        "valor": "Sudán (el)"
    },
    {
        "clave": "SSD",
        "valor": "Sudán del Sur"
    },
    {
        "clave": "SWE",
        "valor": "Suecia"
    },
    {
        "clave": "CHE",
        "valor": "Suiza"
    },
    {
        "clave": "SUR",
        "valor": "Surinam"
    },
    {
        "clave": "SJM",
        "valor": "Svalbard y Jan Mayen"
    },
    {
        "clave": "THA",
        "valor": "Tailandia"
    },
    {
        "clave": "TWN",
        "valor": "Taiwán (Provincia de China)"
    },
    {
        "clave": "TZA",
        "valor": "Tanzania, República Unida de"
    },
    {
        "clave": "TJK",
        "valor": "Tayikistán"
    },
    {
        "clave": "IOT",
        "valor": "Territorio Británico del Océano Índico (el)"
    },
    {
        "clave": "ATF",
        "valor": "Territorios Australes Franceses (los)"
    },
    {
        "clave": "TLS",
        "valor": "Timor-Leste"
    },
    {
        "clave": "TGO",
        "valor": "Togo"
    },
    {
        "clave": "TKL",
        "valor": "Tokelau"
    },
    {
        "clave": "TON",
        "valor": "Tonga"
    },
    {
        "clave": "TTO",
        "valor": "Trinidad y Tobago"
    },
    {
        "clave": "TUN",
        "valor": "Túnez"
    },
    {
        "clave": "TKM",
        "valor": "Turkmenistán"
    },
    {
        "clave": "TUR",
        "valor": "Turquía"
    },
    {
        "clave": "TUV",
        "valor": "Tuvalu"
    },
    {
        "clave": "UKR",
        "valor": "Ucrania"
    },
    {
        "clave": "UGA",
        "valor": "Uganda"
    },
    {
        "clave": "URY",
        "valor": "Uruguay"
    },
    {
        "clave": "UZB",
        "valor": "Uzbekistán"
    },
    {
        "clave": "VUT",
        "valor": "Vanuatu"
    },
    {
        "clave": "VAT",
        "valor": "Santa Sede[Estado de la Ciudad del Vaticano] (la)"
    },
    {
        "clave": "VEN",
        "valor": "Venezuela, República Bolivariana de"
    },
    {
        "clave": "VNM",
        "valor": "Viet Nam"
    },
    {
        "clave": "WLF",
        "valor": "Wallis y Futuna"
    },
    {
        "clave": "YEM",
        "valor": "Yemen"
    },
    {
        "clave": "DJI",
        "valor": "Yibuti"
    },
    {
        "clave": "ZMB",
        "valor": "Zambia"
    },
    {
        "clave": "ZWE",
        "valor": "Zimbabue"
    },
    {
        "clave": "ZZZ",
        "valor": "Países no declarados"
    }
]

export default opcionesResidenciaFiscal;