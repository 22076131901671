import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography
} from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning';

export default function DialogErrorHandler({ useOpen, error }) {

    const [open, setOpen] = useOpen

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth >
            <DialogTitle sx={{ margin: '0 auto' }} >
                <WarningIcon fontSize='large' sx={{ color: '#dba716', textAlign: 'center' }} />
            </DialogTitle>

            <DialogContent>

                {error === 'nombre' &&
                    <Typography sx={{ textAlign: 'center' }} >
                        El campo de nombre no puede quedar vacío.
                    </Typography>
                }
                {error === 'rfc' &&
                    <Typography sx={{ textAlign: 'center' }} >
                        Hay un problema con el RFC.
                        Este debe ser de 12 o 13 dígitos, sin espacios, comas ni guiones.
                        Ejemplo: XAXX010101000
                    </Typography>
                }
                {error === 'rfc-mes' &&
                    <Typography sx={{ textAlign: 'center' }} >
                        Hay un problema con el RFC.
                        El mes en la fecha que contiene no puede superar el número 12.
                    </Typography>
                }
                {error === 'rfc-dia' &&
                    <Typography sx={{ textAlign: 'center' }} >
                        Hay un problema con el RFC.
                        El número de día en la fecha que contiene es inválido.
                    </Typography>
                }
                {error === 'resF' &&
                    <Typography sx={{ textAlign: 'center' }} >
                        Favor de seleccionar una residencia fiscal.
                    </Typography>
                }
                {error === 'IDrt' &&
                    <Typography sx={{ textAlign: 'center' }} >
                        El campo del ID de registro tributario no puede quedar vacío.
                    </Typography>
                }
                {error === 'regF' &&
                    <Typography sx={{ textAlign: 'center' }} >
                        Favor de seleccionar un régimen fiscal.
                    </Typography>
                }
                {error === 'cp' &&
                    <Typography sx={{ textAlign: 'center' }} >
                        El código postal no se ha localizado. Favor de verificarlo.
                    </Typography>
                }
                {error === 'usoCFDI' &&
                    <Typography sx={{ textAlign: 'center' }} >
                        Favor de seleccionar un uso para el CFDI.
                    </Typography>
                }
                {error === 'yaExiste' &&
                    <Typography sx={{ textAlign: 'center' }} >
                        Este registro no puede ser guardado porque su número de cliente es inválido.
                        Favor de volver a intentarlo, usando el enlace enviado por correo electrónico.
                    </Typography>
                }

            </DialogContent>

            <DialogActions sx={{ margin: '0 auto' }} >
                <Button onClick={() => setOpen(false)} >Aceptar</Button>
            </DialogActions>

        </Dialog>
    )
}