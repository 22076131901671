import db from './firebaseData';
import { doc, getDoc } from 'firebase/firestore';






export default async function validarPwd(pwd) {

    const docRef = doc(db, "pwd-links", 'pwd');
    const docSnap = await getDoc(docRef);


    if (pwd === docSnap.data().validar) { 
        
        return true 
    
    }


    return false
}