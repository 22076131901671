import db from './firebaseData';
import { collection, doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

export default async function DBdemosrtarQueAbrioFormulario(pkCliente, nombre) {

    const docName = pkCliente;

    const existe = await existeRegistro(docName)
    if (existe){
        return false
    } else {
        const dataRef = collection(db, 'abrioElFormulario')
        await setDoc(doc(dataRef, docName), {
            pkCliente: pkCliente,
            nombre: (nombre === undefined ? 'Nombre no definido' : nombre),
            timestamp: serverTimestamp()
        })
        return true
    }
}

async function existeRegistro(pkCliente) {
    const docRef = doc(db, "abrioElFormulario", pkCliente);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return true
    } else {
        return false
    }
}