export default function validarRFC(rfc, setError) {
    if (rfc.length === 13) {
        let a05 = rfc.charAt(4)
        let a06 = rfc.charAt(5)
        let a07 = rfc.charAt(6)
        let a08 = rfc.charAt(7)
        let a09 = rfc.charAt(8)
        let a10 = rfc.charAt(9)

        const año = a05 + a06
        if (isNaN(parseInt(año))) { return false }

        const mes = a07 + a08
        const mesNum = parseInt(mes)
        if (isNaN(mesNum)) {
            setError('rfc')
            return false
        }
        if (mesNum > 12) {
            setError('rfc-mes')
            return false
        }

        const dia = a09 + a10
        const diaNum = parseInt(dia)
        if (isNaN(diaNum)) {
            setError('rfc')
            return false
        }
        if(diaNum > 31) {
            setError('rfc-dia')
            return false
        }

        return true

    } else if (rfc.length === 12) {

        let a04 = rfc.charAt(3)
        let a05 = rfc.charAt(4)
        let a06 = rfc.charAt(5)
        let a07 = rfc.charAt(6)
        let a08 = rfc.charAt(7)
        let a09 = rfc.charAt(8)

        const año = a04 + a05
        if (isNaN(parseInt(año))) { return false }

        const mes = a06 + a07
        const mesNum = parseInt(mes)
        if (isNaN(mesNum)) {
            setError('rfc')
            return false
        }
        if (mesNum > 12) {
            setError('rfc-mes')
            return false
        }

        const dia = a08 + a09
        const diaNum = parseInt(dia)
        if (isNaN(diaNum)) {
            setError('rfc')
            return false
        }
        if(diaNum > 31) {
            setError('rfc-dia')
            return false
        }

        return true

    } else {
        setError('rfc')
        return false
    }
}