import db from './firebaseData';
import { collection, doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

export default async function dbGuardar(data) {

    const docName = data.pkCliente === '' ? 'invalid_pkCliente' : data.pkCliente;
    let intento = 1

    async function intentarGuardar(nombre) {
        const existe = await existeRegistro(nombre)
        if (existe) {
            intento = intento + 1
            return intentarGuardar(`${docName} (${intento})`)
        } else {
            const dataRef = collection(db, 'transportes')
            await setDoc(doc(dataRef, nombre), {
                nombre: data.nombre,
                rfc: data.rfc,
                residenciaFiscal: data.residenciaFiscal,
                IDregistroTributario: data.IDregistroTributario,
                regimenFiscal: data.regimenFiscal,
                codigoPostal: data.codigoPostal,
                usoDeCFDI: data.usoDeCFDI,
                pkCliente: data.pkCliente,
                timestamp: serverTimestamp()
            })
            return true
        }
    }

    if (docName !== 'invalid_pkCliente') {
        return await intentarGuardar(docName)
    } else { return false }
}

async function existeRegistro(pkCliente) {
    const docRef = doc(db, "transportes", pkCliente);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return true
    } else {
        return false
    }
}

