const opcionesRegimenFiscal = [
    {
        "clave": 601,
        "valor": "General de Ley Personas Morales"
    },
    {
        "clave": 603,
        "valor": "Personas Morales con Fines no Lucrativos"
    },
    {
        "clave": 605,
        "valor": "Sueldos y Salarios e Ingresos Asimilados a Salarios"
    },
    {
        "clave": 606,
        "valor": "Arrendamiento"
    },
    {
        "clave": 607,
        "valor": "Régimen de Enajenación o Adquisición de Bienes"
    },
    {
        "clave": 608,
        "valor": "Demás ingresos"
    },
    {
        "clave": 610,
        "valor": "Residentes en el Extranjero sin Establecimiento Permanente en México"
    },
    {
        "clave": 611,
        "valor": "Ingresos por Dividendos (socios y accionistas)"
    },
    {
        "clave": 612,
        "valor": "Personas Físicas con Actividades Empresariales y Profesionales"
    },
    {
        "clave": 614,
        "valor": "Ingresos por intereses"
    },
    {
        "clave": 615,
        "valor": "Régimen de los ingresos por obtención de premios"
    },
    {
        "clave": 616,
        "valor": "Sin obligaciones fiscales"
    },
    {
        "clave": 620,
        "valor": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos"
    },
    {
        "clave": 621,
        "valor": "Incorporación Fiscal"
    },
    {
        "clave": 622,
        "valor": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"
    },
    {
        "clave": 623,
        "valor": "Opcional para Grupos de Sociedades"
    },
    {
        "clave": 624,
        "valor": "Coordinados"
    },
    {
        "clave": 625,
        "valor": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas"
    },
    {
        "clave": 626,
        "valor": "Régimen Simplificado de Confianza"
    }
]

export default opcionesRegimenFiscal;