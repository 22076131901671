const opcionesUsoCfdi = [
    {
        "clave": "G01",
        "valor": "Adquisición de mercancías"
    },
    {
        "clave": "G02",
        "valor": "Devoluciones, descuentos o bonificaciones"
    },
    {
        "clave": "G03",
        "valor": "Gastos en general"
    },
    {
        "clave": "I01",
        "valor": "Construcciones"
    },
    {
        "clave": "I02",
        "valor": "Mobiliario y equipo de oficina por inversiones"
    },
    {
        "clave": "I03",
        "valor": "Equipo de transporte"
    },
    {
        "clave": "I04",
        "valor": "Equipo de computo y accesorios"
    },
    {
        "clave": "I05",
        "valor": "Dados, troqueles, moldes, matrices y herramental"
    },
    {
        "clave": "I06",
        "valor": "Comunicaciones telefónicas"
    },
    {
        "clave": "I07",
        "valor": "Comunicaciones satelitales"
    },
    {
        "clave": "I08",
        "valor": "Otra maquinaria y equipo"
    },
    {
        "clave": "D01",
        "valor": "Honorarios médicos, dentales y gastos hospitalarios"
    },
    {
        "clave": "D02",
        "valor": "Gastos médicos por incapacidad o discapacidad"
    },
    {
        "clave": "D03",
        "valor": "Gastos funerales"
    },
    {
        "clave": "D04",
        "valor": "Donativos"
    },
    {
        "clave": "D05",
        "valor": "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)"
    },
    {
        "clave": "D06",
        "valor": "Aportaciones voluntarias al SAR"
    },
    {
        "clave": "D07",
        "valor": "Primas por seguros de gastos médicos"
    },
    {
        "clave": "D08",
        "valor": "Gastos de transportación escolar obligatoria"
    },
    {
        "clave": "D09",
        "valor": "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones"
    },
    {
        "clave": "D10",
        "valor": "Pagos por servicios educativos (colegiaturas)"
    },
    {
        "clave": "S01",
        "valor": "Sin efectos fiscales"
    },
    {
        "clave": "CP01",
        "valor": "Pagos"
    },
    {
        "clave": "CN01",
        "valor": "Nómina"
    }
]

export default opcionesUsoCfdi;